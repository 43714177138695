import React from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider';
import UserProvider from '../../providers/UserProvider';
import { toast } from 'react-toastify';

export default function PrivateRoutes() {
    // const { loading, logged } = useAuth();
    const { loading, logged } = useAuth();

    // const loading = false;
    // const logged = true;

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }

    if(!logged) {
        toast.error("Please login to access this page");
    }

    return (
        logged ?
            <>
                <UserProvider>
                    <Outlet />
                </UserProvider>
            </>
            : <Navigate to='/login' />
    )
}

