import React from 'react';
import { Box, Text, VStack, HStack, Flex, Image } from "@chakra-ui/react"

export default function About() {
    return (
        <>
            <VStack
                w='100%'
                justifyContent={'space-between'}
                alignItems={'center'}
                p={0}
            >
                <Box
                    bgImage='./home_banner.png'
                    bgSize='cover'
                    bgPosition='center'
                    minH={'60vh'}
                    maxH={'75vh'}
                    w={'100%'}
                />
                <HStack
                    w='100%'
                    justifyContent={'center'}
                    alignItems={'start'}
                    p='4'
                    my='10'
                >
                    {/* <Flex
                            h={'100%'}
                            w={'40%'}
                            justifyContent={'center'}
                            alignItems={'center'}>
                            <img src='./aboutus_founder.png' alt=''
                                h={'90%'}
                                w={'92%'} />
                        </Flex> */}
                    <VStack
                        width={['90%', '80%', '60%', '50%']}
                        h={'100%'}
                        justifyContent={'flex-center'}
                        alignItems={'flex-center'}>

                        <Text
                            fontSize={'20px'}
                            fontWeight={700}
                            color={'#000000'}
                            mb={2}
                        >
                            Launched in 2018, Coco is a luxury clothing brand for kids.

                        </Text>
                        <Text
                            fontSize={'lg'}
                            color={'#000000'}>
                            Rohini Nishar, our founder noticed a dearth of fine quality, comfortable and sophisticated clothing for kids. Rohini took cognizance of this opportunity to venture into this space and launched the brand with the vision to change children’s fashion landscape in India.
                        </Text>
                        <Text
                            fontSize={'lg'}
                            color={'#000000'}>
                            The brand offers unique, handcrafted, top notch quality apparel as well as accessories of finest quality for kids.
                            We create aesthetically pleasing outfits keeping in mind these important rules- comfort, style and quality.
                        </Text>
                        <Text
                            fontSize={'lg'}
                            color={'#000000'}>
                            At Coco, we aim to please our clients with our best quality and head turning products.
                        </Text>
                        {/* <Text
                                fontSize={'29px'}
                                color={'#000000'}
                                mt={8}
                                mb={2}
                                fontWeight={700}
                            >
                                THE FOUNDER
                            </Text>
                            <Text
                                fontSize={'lg'}
                                color={'#000000'}>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                            </Text> */}
                    </VStack>

                </HStack>
            </VStack>

        </>
    )
}
