import { VStack, Text, Box, HStack } from '@chakra-ui/react'
import React from 'react'
import { FaRupeeSign } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const HomeRowCard = (props) => {
    return (
        <Box
            w={'100%'}
            as={Link}
            to={`/product/1`}
            display={'flex'}
            flexDirection={'column'}
            minH={['300px', '300px', '500px', '500px']}
            _hover={
                {
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }
            }
            justifyContent={'space-between'} alignItems={'center'}>

            <Box
                backgroundImage={props.item.product_variants.image1}
                backgroundSize={'cover'}
                backgroundPosition={'center'}
                backgroundRepeat={'no-repeat'}
                h={'75%'}
                w={'100%'}
            />
            <VStack h={'25%'} w={'100%'}
                justifyContent={'center'} alignItems={'center'}>
                <Text
                    fontSize={['md', 'md', 'lg', 'xl']}>{props.item.products.name}</Text>
                <HStack>
                    <Text
                        textDecoration={'line-through'}
                        textDecorationColor='red'
                        color={'gray.700'}
                        fontSize={['md', 'md', 'lg', 'xl']}>{props.item.product_variants.mrp}</Text>
                    <Text
                        alignItems='baseline'
                        justifyContent='end'
                        h='100%'
                        fontWeight={'800'}
                        color={'#000000'}
                        fontSize={['sm', 'sm', 'xl', 'xl']}
                    ><FaRupeeSign style={{ display: 'inline-block' }} size='14' /> {props.item.product_variants.selling_price}
                    </Text>
                </HStack>
            </VStack>
        </Box >
    )
}

export default HomeRowCard