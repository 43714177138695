import {
    Menu,
    MenuButton,
    Text,
    Button,
    MenuList,
    VStack,
    HStack,
    useDisclosure,
    Image,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Input,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react'
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

export default function NavLinks() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { logged, logout } = useAuth()
    const { isOpen: isProductsOpen, onOpen: onProductsOpen, onClose: onProductsClose } = useDisclosure();

    return (
        <HStack
            color='#1c1c1c'
            h={'100%'}
            w={'99%'}
            justifyContent={'space-between'}>
            <HStack
                w={'5%'}
                h={'100%'}
                gap={4}
                justifyContent={'center'}
                onClick={onOpen}
                _hover={
                    { cursor: 'pointer' }
                }
            >
                <IoSearchOutline color='white' size={25} />
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Search for a product</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb={2}>
                        <Input placeholder="search here..."
                            border='1px solid grey'
                            _focusVisible={
                                {
                                    border: '1px solid grey'
                                }
                            }
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <HStack
                // bg='red'
                w='100%'
                // w={'35%'}
                h={'100%'}
                justifyContent={'space-evenly'}

            >
                <Link to={`/`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost'
                        borderRadius='lg'
                    >
                        Home
                    </Button>
                </Link>

                <Link to={`/about`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost'
                        borderRadius='lg'>
                        About Us
                    </Button>
                </Link>

                <Menu isOpen={isProductsOpen} matchWidth={true}>
                    {({ isOpen }) => (
                        <>
                            <MenuButton
                                isActive={isOpen}
                                as={Button}
                                bg='primary'
                                borderRadius='lg'
                                onMouseEnter={onProductsOpen}
                                onMouseLeave={onProductsClose}
                                rightIcon={null}
                                fontSize={['sm', 'md', 'md', 'xl']}
                                // color='#FFFFFF'
                                h={'100%'}
                                fontWeight={400}
                                _active={{
                                    bg: 'none'
                                }}
                            >
                                Shop
                            </MenuButton>

                            <MenuList
                                // w='75vw'
                                // mx={'-30vw'}
                                w='55vw'
                                mx={'-20vw'}
                                mt='-1'
                                onMouseEnter={onProductsOpen} onMouseLeave={onProductsClose} p='0'
                                //  bg='white'
                                bg='#054E47'
                                border={'none'}
                            >
                                <HStack p='10' alignItems='start' w={'100%'} justifyContent={'center'} h='100%'>

                                    <VStack alignItems='start' justifyContent='space-between' h='100%' mx={10} gap={6} my={2}>
                                        <Text fontSize='2xl' className='custom' color={'white'}>Categories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Celebrities Closet</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Categories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Accesories</Text>
                                    </VStack>
                                    <VStack alignItems='start' justifyContent='space-between' h='100%' mx={10} gap={6} my={2}>
                                        <Text fontSize='2xl' className='custom' color={'#054E47'}>a</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Celebrities Closet</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Categories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Accesories</Text>
                                    </VStack>


                                    <VStack alignItems='start' justifyContent='space-between' h='100%' mx={10} gap={6} my={2}>
                                        <Text fontSize='2xl' className='custom' color={'white'}>Collections</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Celebrities Closet</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Categories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Accesories</Text>
                                    </VStack>
                                    <VStack alignItems='start' justifyContent='space-between' h='100%' mx={10} gap={6} my={2}>
                                        <Text fontSize='2xl' className='custom' color={'white'}>Accesories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Celebrities Closet</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Categories</Text>
                                        <Text fontSize='md' className='custom' color={'white'}>Accesories</Text>
                                    </VStack>

                                </HStack>

                            </MenuList>
                        </>
                    )}
                </Menu>

                <Flex
                    as={Link}
                    to='/'
                    justifyContent={'center'}
                    alignItems={'center'}
                    w={'18%'}
                    zIndex={1}
                    p={4}>
                    <img
                        src={'/logo-dark.png'}
                        alt='Logo'
                        height={'100px'}
                        weight={'100%'}
                    />
                </Flex>


                <Link to={`/contact`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        // color='#FFFFFF'
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost'
                        borderRadius='lg'>
                        Contact Us
                    </Button>
                </Link>

                <Link to={`/wishlist`}>
                    <Button
                        fontSize={['sm', 'md', 'md', 'xl']}
                        // color='#FFFFFF'
                        _hover={
                            { bg: 'none' }
                        }
                        h={'100%'}
                        fontWeight={400}
                        variant='ghost'
                        borderRadius='lg'>
                        Wishlist
                    </Button>
                </Link>
                {
                    logged ? <Menu>
                        <MenuButton
                            bg='none'
                            color='white'
                            fontSize={['sm', 'md', 'md', 'xl']}
                            _hover={{
                                bg: 'none',
                                color: 'white'
                            }}
                            _active={{
                                bg: 'none'
                            }}
                            as={Button}
                            rightIcon={<ChevronDownIcon />}>
                            My Account
                        </MenuButton>
                        <MenuList
                            gap={5}
                            maxW={'10vw'}
                            bg='#054E47'
                            border={'none'}
                            mt={2}
                        >
                            <VStack
                                w={'100%'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                pl={2}
                            >
                                <Button
                                    pl={7}
                                    _hover={{
                                        bg: 'none',
                                        color: 'white'
                                    }}
                                    bg='none'
                                    color={'white'}
                                    fontSize={['sm', 'md', 'md', 'lg']}
                                    my={1}
                                >
                                    My Profile
                                </Button>
                                <Button
                                    _hover={{
                                        bg: 'none',
                                        color: 'white'
                                    }}
                                    bg='none'
                                    color={'white'}
                                    pl={7}
                                    fontSize={['sm', 'md', 'md', 'lg']}
                                    my={1}
                                >
                                    Wishlist
                                </Button>
                                <Button
                                    _hover={{
                                        bg: 'none',
                                        color: 'white'
                                    }}
                                    bg='none'
                                    color={'white'}
                                    pl={7}
                                    fontSize={['sm', 'md', 'md', 'lg']}
                                    my={1}
                                >
                                    Orders
                                </Button>
                                <Button
                                    _hover={{
                                        bg: 'none',
                                        color: 'white'
                                    }}
                                    bg='none'
                                    color={'white'}
                                    pl={7}
                                    fontSize={['sm', 'md', 'md', 'lg']}
                                    my={1}
                                    onClick={logout}
                                >
                                    Logout
                                </Button>
                            </VStack>
                        </MenuList>
                    </Menu>
                        :
                        <Link to={`/login`}>
                            <Button
                                fontSize={['sm', 'md', 'md', 'xl']}
                                // color='#FFFFFF'
                                h={'100%'}
                                fontWeight={400}
                                variant='ghost'
                                borderRadius='lg'
                                _hover={
                                    { bg: 'none' }
                                }
                            >
                                Sign in
                            </Button>
                        </Link>

                }
            </HStack>

            <HStack
                as={Link}
                to={'/cart'}
                spacing='1'
                w={'5%'}
            >
                <Image
                    src={'/cart.png'}
                    alt=''
                    h={'28px'}
                    w={'28px'}
                />
                <Flex
                    borderRadius={'50%'}
                    bg={'#ECE7E3'}
                    color={'#966754'}
                    w={'20px'}
                    h={'20px'}
                    justifyContent={'center'}
                    alignItems='center'
                >
                    1
                </Flex>

            </HStack>
        </HStack>
    )
} 